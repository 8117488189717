import React from 'react'
import styled from 'styled-components'
import EmbeddedVideo from '../components/EmbeddedVideo'
import {
  colors,
  typography
} from '../styles'
import Button from './Button'
import Modal from './Modal'
import RichText from './RichText'

const VideoBlogModal = ({
  closeModal,
  data: {
    videoAsset,
    embeddedVideo,
    videoTitle,
    videoDescription,
    videoButton
  }
}) => {

  return (
    <Modal closeModal={closeModal} >
      <VideoBlogModal_wrapper>
        {videoAsset && (
          <Video
            playsInline
            controls >
            <source
              src={videoAsset.file.url}
              type={'video/mp4' || contentType} />
          </Video>
        )}
        {(!videoAsset && embeddedVideo) && <EmbeddedVideo video={embeddedVideo} />}
        {videoTitle && <Title>{videoTitle}</Title>}
        {videoDescription && (
          <Description>
            <RichText json={videoDescription.json} />
          </Description>
        )}
        {videoButton && (
          <Button_wrapper>
            <Button
              title={videoButton.title}
              link={videoButton.urlLink}
              type='small'
              color='black' />
          </Button_wrapper>
        )}
      </VideoBlogModal_wrapper>
    </Modal>
  )
}

const VideoBlogModal_wrapper = styled.div`
  text-align: center
`

const Title = styled.div`
  ${typography.body.big}
  max-width: 400px;
  margin: 15px auto 0;
`

const Description = styled.div`
  ${typography.body.regular}
  max-width: 500px;
  margin: 15px auto 0;
  color: ${colors.darkGrey};

  p { margin: 0 }
`

const Video = styled.video`
  width: 100%;
  height: calc(80vw / 1.5);
  max-height: 350px;
  object-fit: cover;
`

const Button_wrapper = styled.div`
  margin-top: 25px;
`

export default VideoBlogModal