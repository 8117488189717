import React from 'react'
import styled from 'styled-components'
import {
  colors,
  typography,
  VARIABLE_CONSTANTS,
  VW
} from '../../styles'
import RichText from '../RichText'

const BlogContent = ({
  data: {
    headline: {
      json: headline
    },
    text: {
      json: text
    }
  },
  isEven
}) => {
  return (
    <BlogContent_wrapper isEven={isEven}>
      <BlogContent_container>
        <Title>
          <RichText json={headline} />
        </Title>
        <Text_hidden>
          <RichText json={text} />
        </Text_hidden>
        <Text_visible>
          <RichText json={text} />
        </Text_visible>
      </BlogContent_container>
    </BlogContent_wrapper>
  )
}

const BlogContent_wrapper = styled.div`
  position: relative;
  background-color: ${colors.red};
  min-height: calc((100vw - 40px) * 4 / 5 + ${VARIABLE_CONSTANTS.mobileBlogSpacing});

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: ${props => props.isEven ?
      'translate(-8%,-10%) scale(2.05)' :
      'translate(8%,-10%) scale(2.05) scaleX(-1)'
    };
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%22481%22%20height%3D%22406%22%20viewBox%3D%220%200%20481%20406%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M397.633%20234.039C397.219%20217.643%20394.904%20203.588%20390.026%20189.509C384.703%20174.144%20378.231%20158.865%20369.226%20146.841C357.929%20131.756%20343.421%20119.891%20328.163%20110.575C317.203%20103.882%20302.43%2097.0032%20291.087%2091.9275C265.625%2080.5337%20239.868%2069.2439%20213.249%2060.4479C195.68%2054.6426%20176.81%2051.2135%20157.856%2049.525C134.429%2047.4379%20106.807%2050.7159%2083.6967%2072.2484C73.7773%2081.4908%2059.546%20100.363%2056.2894%20114.706C50.2857%20141.149%2059.3789%20166.139%2068.8959%20183.882C83.8596%20211.78%20113.081%20243.608%20133.999%20263.797C159.569%20288.476%20188.108%20310.236%20218.266%20327.603C243.053%20341.877%20270.041%20354.963%20298.411%20361.299C316.315%20365.297%20337.521%20365.013%20357.341%20356.143C377.664%20347.047%20398.001%20329.315%20414.327%20310.068C424.876%20297.632%20431.417%20284.713%20431.653%20268.96C431.924%20250.907%20423.6%20234.663%20416.452%20221.737C396.994%20186.552%20366.455%20165.037%20336.898%20143.24C309.394%20122.957%20281.253%20104.524%20251.208%2088.7842C238.782%2082.275%20226.289%2075.8952%20213.722%2069.6658%22%20stroke%3D%22%23D23D4A%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    z-index: -1;
  }

  @media (min-width: ${VW.desktop}) {
    min-height: calc(((100vw - 40px) * 0.4082644628) * 1.2527472527);
  }

  @media (min-width: 1200px) {
    min-height: calc(488px * 1.2527472527);
  }
`

const BlogContent_container = styled.div`
  height: 100%;
  color: white;
  padding: 10% 6%;

  @media (min-width: ${VW.desktop}) {
    padding: 8% 8.5% 7%;
  }
`

const Title = styled.div`
  ${typography.body.big}
  margin-bottom: 40px;

  p { margin: 0 }

  @media (min-width: ${VW.desktop}) {
    ${typography.headlines.regular}
    padding-left: 7.5%;
  }
`

const Text_hidden = styled.div`
  ${typography.body.small}
  opacity: 0;
  pointer-events: none;

  p { margin: 0 }
`

const Text_visible = styled.div`
  ${typography.body.small}
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 6% 10%;

  p { margin: 0 }

  @media (min-width: ${VW.desktop}) {
    padding: 0 8.5% 7%;
  }
`

export default BlogContent