import React, { useEffect, useRef, useState, useCallback } from 'react'
import styled from 'styled-components'
import { colors } from '../../styles'
import RichText from '../RichText'
import VideoBlogModal from '../VideoBlogModal'

const BlogVideo = ({ data }) => {
  const modalData = data && data.videoModal
  const text = data && data.text && data.text.json || ''
  const contentType = data && data.video && data.video.file && data.video.file.contentType || ''
  const url = data && data.video && data.video.file && data.video.file.url || ''

  const video_ref = useRef()
  const play_ref = useRef()
  const [openModal, setOpenModal] = useState()

  let intervalId

  useEffect(() => {
    const video = video_ref.current

    video.addEventListener("mouseenter", handleMouseEnter)
    video.addEventListener("mouseleave", handleMouseLeave)

    return () => {
      video.removeEventListener("mouseenter", handleMouseEnter)
      video.removeEventListener("mouseleave", handleMouseLeave)
    }
  }, [video_ref])

  const handleMouseEnter = () => {
    const video = video_ref.current
    const play = play_ref.current
 
    video.play()

    let count = 1

    play.classList.add('blink')

    intervalId = setInterval(() => {
      if(play.classList.contains('blink')) {
        play.classList.remove('blink')

      } else {
        play.classList.add('blink')

        if (count++ === 4) { clearInterval(intervalId) }
      }    
    }, 300)
  }

  const handleMouseLeave = () => {
    const video = video_ref.current
    const play = play_ref.current

    clearInterval(intervalId)
    video.pause()

    if(play.classList.contains('blink')) {
      play.classList.remove('blink')
    }
  }

  const handleClick = useCallback(() => {
    if(!modalData) { return }

    setOpenModal(true)
  }, [modalData])

  const closeModal = useCallback(() => {
    setOpenModal(false)
  }, [])

  return (
    <>
      <BlogVideo_wrapper>
        <Video
          hasModal={!!modalData}
          onClick={handleClick} >
          <video
            ref={video_ref}
            muted
            loop
            playsInline >
            <source
              src={url}
              type={'video/mp4' || contentType} />
          </video>
          <Play_wrapper ref={play_ref}>
            <Play>Play</Play>
          </Play_wrapper>
        </Video>
        <div className='content-wrapper'>
          <div className='content-container'>
            <RichText
              json={text}
              hasAnchor />
          </div>
        </div>
      </BlogVideo_wrapper>
      {openModal && (
        <VideoBlogModal
          data={modalData}
          closeModal={closeModal} />
      )}
    </>
  )
}

const BlogVideo_wrapper = styled.div`
  position: relative;

  video {
    width: 100%;
    object-fit: contain;
  }
`

const Video = styled.div`
  position: relative;
  cursor: ${props => props.hasModal ? 'pointer' : 'initial'}
`

const Play_wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.2s ease;

  &.blink {
    opacity: 0;
  }
`

const Play = styled.div`
  position: relative;
  color: white;
  font-size: 35px;
  padding-left: 40px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: ${colors.red};
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
`

export default BlogVideo