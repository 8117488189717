import React from 'react'
import styled from 'styled-components'
import Image from '../shared/Image'

const BlogImageAsset = ({data: {
  image: {
    fluid,
    description
  }
}}) => {

  return (
    <BlogImage>
      <Image
        fluid={fluid}
        description={description} />
    </BlogImage>
  )
}

const BlogImage = styled.div``

export default BlogImageAsset