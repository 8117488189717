import React from 'react'
import styled from 'styled-components'
import {
  typography,
  VW
} from '../../styles'
import Button from '../Button'
import RichText from '../RichText'
import Image from '../shared/Image'

const BlogImageLink = ({ data: {
  image: {
    fluid,
    description
  },
  button: {
    title: buttonTitle,
    urlLink: buttonLink
  },
  text: {
    json: text
  }
}}) => {

  return (
    <BlogImageLink_wrapper>
      <Image
        fluid={fluid}
        description={description} />
      <div className="content-wrapper">
        <div className="content-container">
          <RichText
            json={text}
            hasAnchor />
          <Button
            title={buttonTitle}
            link={buttonLink}
            type='small'
            color='black' />
        </div>
      </div>
    </BlogImageLink_wrapper>
  )
}

const BlogImageLink_wrapper = styled.div`
  .button {
    display: inline-block;
    margin-top: 15px;
  }

  @media (min-width: ${VW.desktop}) {
    ${typography.body.big}
  }
`

export default BlogImageLink