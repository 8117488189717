import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'
import Image from '../shared/Image'
import {
  icons,
  colors
} from '../../styles'
import RichText from '../RichText'

let debounce

const BlogCarousel = ({data: {
  images,
  text
}
}) => {
  const content_ref = useRef();
  const slick_ref = useRef();
  const [contentHeight, setContentHeight] = useState()

  const Arrow = ({ direction }) => {
    return (
      <div
        className={`carousel-direction-${direction}`}
        onClick={() => handleClick(direction)} >
        {icons[`${direction}_carousel_arrow`]}
      </div>
    )
  }

  const handleClick = (direction) => {
    if(direction === 'left') {
      slick_ref.current.slickPrev()
      
    } else {
      slick_ref.current.slickNext()
    }
  }

  const setNewHeight = () => {
    const contentHeight = content_ref.current.clientHeight
    setContentHeight(contentHeight)
  }

  const handleResize = () => {
    clearTimeout(debounce);
    debounce = setTimeout(() => setNewHeight(), 100)
  }

  useEffect(() => {
    if(content_ref.current) {
      setNewHeight()
      if(typeof window !== 'undefined') {
        window.addEventListener('resize', () => {
          handleResize()
        })
      }
    }
  }, [content_ref])

  const settings = {
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    fade: true,
    dots: true,
    nextArrow: <Arrow direction='right' />,
    prevArrow: <Arrow direction='left' />
  }

  return (
    <BlogCarousel_wrapper contentHeight={contentHeight}>
      <Slider
        {...settings}
        ref={slick_ref} >
        {images.map((image, i) => (
          <Image
            key={`blog-carousel-${i}`}
            fluid={image.fluid}
            alt={image.description} />
        ))}
      </Slider>
      {text && (
        <div
          className="content-wrapper"
          ref={content_ref} >
          <div className="content-container">
            <RichText
              json={text.json}
              hasAnchor
              isTest />
          </div>
        </div>
      )}
    </BlogCarousel_wrapper>
  )
}

const BlogCarousel_wrapper = styled.div`
  position: relative;

  .carousel-direction-left,
  .carousel-direction-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    float: initial;
    height: initial;

    & > div {
      height: 100%;
    }

    picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .carousel-direction-left {
    left: 0;
  }

  .carousel-direction-right {
    right: 0;
  }

  .slick-dots {
    display: flex !important;
    justify-content: space-between;
    bottom: ${props => props.contentHeight ?
      `calc(-${props.contentHeight}px + -15px);` :
      '-7px'
    };

    li {
      width: 100%;
      margin: 0;
      cursor: initial;
      height: initial;

      & + li {
        margin-left: 5px;
      }

      &.slick-active {
        button {
          background-color: ${colors.red};
        }
      }

      button {
        width: 100%;
        height: 3px;
        padding: 0;
        background-color: ${colors.mediumGrey};

        &::before {
          content: none;
        }
      }
    }
  }
`

export default BlogCarousel