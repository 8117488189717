import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  VW,
  icons
} from '../styles'

const FADE_DUR_FACTOR = 3

const Modal = ({
  children,
  closeModal
}) => {
  const [fadeIn, setFadeIn] = useState()

  useEffect(() => {
    if(fadeIn !== undefined && !fadeIn) {
      setTimeout(() => {
        const body = document.querySelector('body')
        body.classList.remove('overflow')
        closeModal()
      }, `${FADE_DUR_FACTOR}00`)
    }
  }, [fadeIn])

  useEffect(() => {
    if(typeof document !== 'undefined') {
      const body = document.querySelector('body')
      body.classList.add('overflow')
      document.addEventListener('keyup', handleKeyUp)
    }

    setFadeIn(true)

    return () => {
      if(typeof document !== 'undefined') {
        const body = document.querySelector('body')
        body.classList.remove('overflow')
        document.removeEventListener('keyup', handleKeyUp)
      }
    }
  }, [])

  const handleKeyUp = useCallback((event) => {
    if(event.keyCode === 27) {
      handleCloseModal()
    }
  }, [handleCloseModal])

  const handleCloseModal = useCallback(() => {
    setFadeIn(false)
  }, [])

  return (
    <Modal_Overlay_Wrapper fadeIn={fadeIn}>
      <Overlay onClick={handleCloseModal} />
      <Modal_wrapper>
        <Modal_container>
          <Close onClick={handleCloseModal}>
            {icons.close_x({
              color: '#000',
              height: '18',
              width: '17'
            })}
          </Close>
          <Modal_content>
            {children}
          </Modal_content>
        </Modal_container>
      </Modal_wrapper>
    </Modal_Overlay_Wrapper>
  )
}

const POS_DESKTOP_V = '10'
const POS_MOBILE_V = '5'

const Modal_Overlay_Wrapper = styled.div`
  position: relative;
  opacity: 0;
  transition: opacity 0.${FADE_DUR_FACTOR}s ease-in-out;
  z-index: 100;
  ${props => props.fadeIn && `
    opacity: 1;
  `}
`

const Overlay = styled.div`
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
`

const Modal_wrapper = styled.div`
  position: fixed !important;
  z-index: 101;
  top: ${POS_MOBILE_V}vh;
  bottom: ${POS_MOBILE_V}vh;
  left: ${POS_MOBILE_V}vw;
  right: ${POS_MOBILE_V}vw;
  overflow: scroll;
  pointer-events: none;

  @media (min-width: ${VW.desktop}) {
    top: ${POS_DESKTOP_V}vh;
    bottom: ${POS_DESKTOP_V}vh;
    left: ${POS_DESKTOP_V}vw;
    right: ${POS_DESKTOP_V}vw;  
  }
`

const Modal_container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: calc(100vh - (${POS_MOBILE_V}vh * 2));
  pointer-events: initial;

  @media (min-width: ${VW.desktop}) {
    max-width: 790px;
    max-height: calc(100vh - (${POS_DESKTOP_V}vh * 2));
  }
`

const Modal_content = styled.div`
  background-color: white;
  padding: 40px;
  border: 1px solid black;
  max-height: calc(100vh - (${POS_MOBILE_V}vh * 2));
  box-sizing: border-box;
  overflow: scroll;

  @media (min-width: ${VW.desktop}) {
    padding: 50px 70px;
    max-height: calc(100vh - (${POS_DESKTOP_V}vh * 2));
  }
`

const Close = styled.div`
  position: absolute;
  top: 14px;
  right: 15px;
  cursor: pointer;
`

export default Modal