import React, { createRef, useEffect, useRef, useState } from 'react'
import BlogCarousel from '../components/blog/BlogCarousel'
import BlogContent from '../components/blog/BlogContent'
import BlogImageAsset from '../components/blog/BlogImageAsset'
import BlogImageLink from '../components/blog/BlogImageLink'
import BlogSpotifyPlaylist from '../components/blog/BlogSpotifyPlaylist'
import BlogVideo from '../components/blog/BlogVideo'
import styled from 'styled-components'
import {
  VW,
  VARIABLE_CONSTANTS,
  typography,
  colors
} from '../styles'

const BlogModules = ({
  sections,
  isHomepage
}) => {
  const modules_ref = useRef(sections.map(() => createRef()))
  const [addedMargin, setAddedMargin] = useState(false)

  useEffect(() => {
    if(typeof document === 'undefined' || addedMargin) { return }

    handleResize()
    window.addEventListener('resize', handleResize)

    setAddedMargin(true)
  }, [addedMargin])

  const handleResize = () => {
    const isMobile = window.innerWidth <= 1024

    for (let i = 0; i < modules_ref.current.length; i++) {
      if(!(i % 2)) {

        const module_first = modules_ref.current[i].current
        let module_second = modules_ref.current[i + 1]
        module_second = module_second && module_second.current

        if(isMobile) {
          if(module_first) {
            module_first.style.marginBottom = VARIABLE_CONSTANTS.mobileBlogSpacing
          }
        
          if(module_second) {
            module_second.style.marginBottom = VARIABLE_CONSTANTS.mobileBlogSpacing
          }

        } else {
          let biggestHeight = 0
  
          if(module_first) {
            const content = module_first.querySelector('.content-container')
            if(content) {
              biggestHeight = content.clientHeight
            }
          }
  
          if(module_second) {
            const content = module_second.querySelector('.content-container')
            if(content && content.clientHeight > biggestHeight) {
              biggestHeight = content.clientHeight
            }
          }

          const setHeight = isHomepage ? 80 : 160

          if(module_first) {
            module_first.style.marginBottom = `${setHeight + biggestHeight}px`
          }

          if(module_second) {
            module_second.style.marginBottom = `${setHeight + biggestHeight}px`
          }
        }
      }
    }
  }

  return (
    <BlogModules_wrapper>
      {sections.map((module, i) => {
        const type = module.sys.contentType.sys.id

        const isEven = !!(i % 2)

        const addedProps = {
          key:`module-${i}`,
          ['data-module']: type,
          isEven
        }

        switch(type) {
          case 'blogModuleCarousel':
            return (
              <Module
                {...addedProps}
                ref={modules_ref.current[i]} >
                <BlogCarousel data={module} />
              </Module>
            )

          case 'blogModuleContent':
            return (
              <Module
                {...addedProps}
                ref={modules_ref.current[i]} >
                <BlogContent
                  data={module}
                  isEven={isEven} />
              </Module>
            )

          case 'blogModuleImageAsset':
            return (
              <Module
                {...addedProps}
                ref={modules_ref.current[i]} >
                <BlogImageAsset data={module} />
              </Module>
            )

          case 'blogModuleImageLink':
            return (
              <Module
                {...addedProps}
                ref={modules_ref.current[i]} >
                <BlogImageLink data={module} />
              </Module>
            )
          
          case 'blogModuleSpotifyPlaylist':
            return (
              <Module
                {...addedProps}
                ref={modules_ref.current[i]} >
                <BlogSpotifyPlaylist data={module} />
              </Module>
            )

          case 'blogModuleVideo':
            return (
              <Module
                {...addedProps}
                ref={modules_ref.current[i]} >
                <BlogVideo data={module} />
              </Module>
            )

          default:
            console.error(`Module > ${type} < Not Found!`)
            return <></>
        }
      })}
    </BlogModules_wrapper>
  )
}

const BlogModules_wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: end;
  column-gap: 20px;

  @media (min-width: ${VW.desktop}) {
    grid-template-columns: repeat(12, 1fr);
  }
`

const Module = styled.div`
  grid-column-start: ${props => props.isEven ? '2' : '1'};
  grid-column-end: ${props => props.isEven ? '6' : '5'};
  background-color: white;
  
  & > div {
    position: relative;
  }

  .content-container {
    padding-top: 15px;

    p {
      ${typography.body.small}
      margin: 0;
    }

    a {
      color: ${colors.red};
    }
  }

  @media (min-width: ${VW.desktop}) {
    grid-column-start: ${props => props.isEven ? '8' : '1'};
    grid-column-end: ${props => props.isEven ? '13' : '6'};

    .content-wrapper {
      position: absolute;
      top: 100%;
      left: 0;
  
      p { margin: 0 }
    }

    .content-container {
      p {
        ${typography.body.big}
        margin: 0;
      }
    }
  }
`

export default BlogModules