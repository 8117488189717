import React from 'react'
import styled from 'styled-components'
import {
  VW
} from '../../styles'
import RichText from '../RichText'

const BlogSpotifyPlaylist = ({ data: {
  spotifyEmbeded: {
    json: spotifyEmbeded
  }
}}) => {
  return (
    <BlogSpotifyPlaylist_wrapper>
      <BlogSpotifyPlaylist_container>
        <RichText
          json={spotifyEmbeded}
          isEmbeded />
      </BlogSpotifyPlaylist_container>
    </BlogSpotifyPlaylist_wrapper>
  )
}

const BlogSpotifyPlaylist_wrapper = styled.div``

const BlogSpotifyPlaylist_container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: calc(100% * 1.2666666667);

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  iframe {
    width: 100% !important;
    height: 100% !important;
  }
`

export default BlogSpotifyPlaylist